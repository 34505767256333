<template>
    <div class="page">
        <div class="align">
            <div class="title">
                Sorry...
            </div>
            <div class="msg">
                {{ msg }}
            </div>
            <van-button
                    block
                    class="close-btn"
                    @click="close"
            >
                关闭页面
            </van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageNotFound',
        data() {
            return {
                msg: '',
            }
        },
        mounted() {
            console.log(this.$route)
            this.msg = this.$route.query.message
        },
        methods: {
            close() {
                WeixinJSBridge.invoke('closeWindow')
            },
        },
    }
</script>

<style
        scoped
        lang="scss"
>
    @import "src/design/common";

    .page {
        height: 100%;
        padding: 15px;
        background: #EBE8E1;
        text-align: center;
        position: relative;

        .align {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -40%);
        }

        .title {
            color: #4b4c50;
            font-size: 24px;
            font-weight: 500;
            padding-bottom: 30px;
        }

        .msg {
            color: #7d7d7d;
            font-weight: 400;
            font-size: 16px;
            padding-bottom: 60px;
        }

        .close-btn {
            background-color: white;
            height: 50px;
            border-radius: 8px;
            font-size: 16px;
            font-family: $font-family;
            font-weight: 400;
            color: $primary-color;
            margin: 0 auto;
            width: 80%;
            border: 2px solid $primary-color;
        }
    }
</style>
